<template>
    <div class="message"
         :class="'from-'+(message.manager_id ? 'manager' : 'client')"
    >
        <div v-if="message.manager_id"  class="avatar">
            <img :src="favicon" alt="">
        </div>
        <div class="text">
            <span v-if="message.manager_id" class="agent label__large">{{tokens.newDesign.agent}}</span>
            <div class="text-content body__medium" :class="{'pre-load': imageLoading}">
                <div v-html="convertText(message.text)"></div>
                <div v-if="['.jpg', '.png', 'jpeg'].indexOf(message.file.slice(-4)) > -1"
                     @click="openImg(restEndpoint+'/kyc/document/'+message.file)"
                     class="pic"
                >
                    <!-- Show loader while image is loading -->
                    <span v-if="imageLoading" class="spin-loader"></span>

                        <img
                            :src="restEndpoint + '/kyc/document/' + message.file"
                            @load="imageLoading = false"
                            @error="imageLoading = false"
                            @loadstart="imageLoading = true"
                            :alt="message.file"
                        >
                </div>
                <a v-if="message.file.slice(-4) === '.pdf'"
                   :href="restEndpoint+'/kyc/document/'+message.file"
                   class="pdf" target="_blank"
                >
                    <i class="material-icons">attach_file</i> {{tokens.newDesign.pdfAttachment }}
                </a>
            </div>
            <div class="footer">
                <slot name="copy" :message="message"/>
                <div class="time">{{ new Date(message.time_created * 1000).toLocaleString() }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {FAVICON, REST_ENDPOINT} from "@/common/config"
import {mapGetters} from "vuex"

export default {
    name: "MessageBox",
    props: {
        message: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            restEndpoint: REST_ENDPOINT,
            favicon: FAVICON,
            imageLoading: false
        }
    },
    computed: {
        ...mapGetters(['tokens'])
    },
    methods: {
        convertText(text) {
            let objRE = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            return text.replace(objRE, url => '<a style="color: var(--white); word-break: break-word;" target="_blank" href="' + url + '">' + url + '</a>')
        },
        openImg(path) {
            this.path[0] = path
            this.toggler = !this.toggler
        },
    }
}
</script>

<style scoped>
.message {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    gap: 10px;
    overflow: hidden;
}

.message:last-child {
    margin-bottom: 0;
}

.message.from-manager {
    justify-content: flex-start;
}

.message.from-client {
    justify-content: flex-end;
}

.avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #000;
}

.avatar img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
}

.message.from-manager .avatar {
    grid-area: 1/1;
}

.message.from-manager .text-content {
    background: var(--System-Surface5);
    padding: 10px 16px;
    border-radius: 0 var(--Corner-small) var(--Corner-small) var(--Corner-small);
}

.message.from-client .text-content {
    background: var(--System-PrimaryContainer);
    padding: 12px 16px;
    border-radius: var(--Corner-small) 0 var(--Corner-small) var(--Corner-small);
}

.message.from-manager .footer {
    justify-content: flex-end;
}

.message.from-client .footer {
    justify-content: flex-start;
    flex-direction: row-reverse;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.agent {
    color: var(--System-OnSurface);
}

.text-content {
    color: var(--System-OnSecondaryContainer);
    word-break: break-word;
    position: relative;
}

.footer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.pic {
    width: 225px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pic img {
    width: inherit;
    height: inherit;
    object-fit: contain;
}

/* loader */
.text-content.pre-load {
    width: 200px;
    height: 200px;
    padding: 12px;
}

.spin-loader {
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}
.spin-loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
</style>
